
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IApp, ICampaign } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP, ACTION_CAMPAIGN } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import CampaignFormModal from './CampaignFormModal.vue'
import TagCodeModal from '@/components/common/ui/TagCodeModal.vue'
import LIFFHowToSetupFormModal from '@/components/campaign-traffic-sources/LIFFHowToSetupFormModal.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: {
    CLoading,
    CampaignFormModal,
    TagCodeModal,
    LIFFHowToSetupFormModal,
    TestModeSelector,
  },
  directives: { maska },
  emits: [],
})
export default class CampaignList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  modalLiffHowtoSetupVisible = false
  // tagCodeModalVisible = false
  selectedCampaign: ICampaign = {}
  selectingApp: IApp = {}
  isTestMode = false

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get campaigns() {
    return cloneDeep(this.$store.getters.campaigns) || []
  }

  @Watch('isTestMode')
  async loadData() {
    this.loading = true
    console.log('isTestModeChange')
    await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
      is_test_mode: this.isTestMode,
    })
    this.loading = false
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)
    this.loadData()
    if (
      !this.selectingApp.liff_id ||
      this.selectingApp.liff_id === '' ||
      (this.selectingApp.is_test_mode && this.selectingApp.liff_id_test === '')
    ) {
      this.modalLiffHowtoSetupVisible = true
    }
  }

  onAdd() {
    this.selectedCampaign = {
      app_id: this.selectedAppId,
      is_active: !this.isTestMode,
      is_test_mode: this.isTestMode,
    }

    this.modalVisible = true
  }

  onCopy(data: ICampaign) {
    const campaignCopy: ICampaign = cloneDeep(data)
    campaignCopy._id = ''
    campaignCopy.copy_campaign_id = data._id
    campaignCopy.is_active = !this.isTestMode
    campaignCopy.is_test_mode = this.isTestMode
    campaignCopy.is_traffic_source = true
    campaignCopy.story_id = ''
    campaignCopy.campaign_name = campaignCopy.campaign_name + ' Copy'
    this.selectedCampaign = campaignCopy
    this.modalVisible = true
  }

  onEdit(data: ICampaign) {
    this.selectedCampaign = data
    this.modalVisible = true
  }

  async onDelete(data: ICampaign) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_CAMPAIGN.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedCampaign = {}
  }

  gotoTraffficSources(event, data: ICampaign) {
    // Check if Ctrl (Windows) or Meta (Mac) is pressed
    if (event.ctrlKey || event.metaKey) {
      // Let the browser handle opening in a new tab
      return
    }
    // Otherwise, navigate programmatically
    event.preventDefault()

    this.goto('traffic_sources', {
      app_id: this.selectedAppId,
      campaign_id: data._id,
      is_test_mode: this.isTestMode,
    })
  }

  gotoTraffficSourcesUrl(data: ICampaign) {
    // Construct the URL as a string for direct navigation
    return this.$router.resolve({
      name: 'traffic_sources',
      params: {
        app_id: this.selectedAppId,
        campaign_id: data._id,
        is_test_mode: this.isTestMode,
      },
    }).href
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  detailPath(name: string, params = {}) {
    return {
      name: name,
      params: params,
    }
  }
}
